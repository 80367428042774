import React, {useState, useEffect} from 'react';
import useStyles from './adminStyle';
import Dialog from '../../globalComponents/dialog';
import MyInput from '../components/input'; 

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import Export from './functions';

dayjs.extend(isToday);
dayjs.extend(AdvancedFormat);
dayjs.extend(isYesterday);
dayjs.extend(isoWeek);


export default function AdminRecords(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [edit, setEdit] = useState(null);
    const [exported, setExported] = useState(null)
    const [editing, setEditing] = useState(null);
    const [allTeachers, setAllTeachers] = useState([]);
    const [allStudents, setAllStudents] = useState([]);
    const [caller, setCaller] = useState([]);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({students: "", teacher: "", hours: "", data: ""});

    function addRecord(){
        const newData = {
            date:new Date(), 
            students:"", 
            teacher:"", 
            hours: "", 
            notes: "", 
            doNotCharge: false, 
            edit: true, 
            lateCancelation: 0,
            rate: ""
        }
        sendSave(newData, true);
    };

    function changeActive(event, index){
        let newData = [...data];
        newData[index].lateCancelation = !newData[index].lateCancelation;
        setData(newData);
    };

    function changeCharge(event, index){
        let newData = [...data];
        newData[index].doNotCharge = !newData[index].doNotCharge;
        setData(newData);
    };

    function changeEdit(event, index, edit){
        let newData = [...data];
        if(editing !== null){
            if(editing !==  index){
                newData[editing].edit = true;
                setEdit(newData[index]);
                setEditing(index);
                sendSave(edit, false);
            } else {
                setEditing(null)
            }
        } else {
            setEdit(newData[index]);
            setEditing(index)
        }
        
        if(!newData[index].edit){
            newData[index] = edit;
            sendSave(edit, false);
        }
        
        newData[index].edit = !newData[index].edit;
        setData(newData)
    };

    function exportXLSX(){
        fetch(`https://londonbeheroku.herokuapp.com/export/students`, {
            method: 'POST',
            body: JSON.stringify({
                body: {date: new Date()}
            })
        })
        setExported({date: new Date().toISOString().split(".")[0]})
        var result = data.map(function(el) {
            var o = Object.assign({}, el);
            var stu = (allStudents.find(element => element.id === parseInt(o.students)) || {name: ""});
            var tea = (allTeachers.find(element => element.id === parseInt(o.teacher)) || {name: ""});
            o.students = stu.name + " " + stu.lastName;
            o.teacher = tea.name + " " + tea.lastName;
            o.date = new Date(o.date);
            return o;
        })
        console.log(result)
        Export(result);
    }

    function handleClose(state){
        if(state === "Yes"){
            sendDelete(caller);
        };
        setOpen(false);
    };

    function myDelete(Id, Index){
        var name;
        var exportDate = data[Index].date.split("T")[0].split("-");
        if(data[Index].teacher !== ""){
            name = allTeachers.find(
                element => element.id === parseInt(data[Index].teacher)
            ).name + " " + allTeachers.find(
                element => element.id === parseInt(data[Index].teacher)
            ).lastName;
        } else {
            name = "";
        }
        setCaller([Id, Index]);
        setText(" taught by " + name + " on date " + exportDate[2] 
                + "/" + exportDate[1] + "/" + exportDate[0]
        );
        setOpen(true);
    }

    function clearFilters(){
        setFilters({students: "", teacher: "", hours: "", data: ""});
    }

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/records`)
            .then(res => res.json())
            .then((data) => {
                var result = data.data.map(function(el) {
                    var o = Object.assign({}, el);
                    o.edit = true;
                    if(o.doNotCharge === 1){
                        o.doNotCharge = true;
                    } else {
                        o.doNotCharge = false;
                    }
                    if(o.lateCancelation === 1){
                        o.lateCancelation = true;
                    } else {
                        o.lateCancelation = false;
                    }
                    return o;
                })
                setExported(data.export);
                setAllTeachers(data.teachers);
                setData(result);
                setAllStudents(data.students)
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function sendSave(Newdata, New){
        fetch(`https://londonbeheroku.herokuapp.com/record/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: Newdata
            })
        })
        .then(res => res.json())
        .then((savedData) => {
            if(New){
                let array = [...data];
                Newdata.id = savedData;
                array.unshift(Newdata);
                setData(array);
            }
        });  
    }
    function sendDelete(idd){
        let newData = [...data];
        fetch(`https://londonbeheroku.herokuapp.com/record/delete`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: idd[0]}
            })
        })
        .then(res => res.json())
        .then((data) => {
            delete newData[idd[1]]
            setData(newData)
        }); 
    }

    function timeStringToFloat(time) {
        var hoursMinutes = time.split(/[.:]/);
        var hours = parseInt(hoursMinutes[0], 10);
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        return (hours + minutes / 60).toFixed(2);
      }

    function handleCopy(index){
        const ref = data[index];
        
        var options = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        navigator.clipboard.writeText(
            new Date(ref.date).toLocaleDateString("en-US", options)+"\t"+
            (allStudents.find(element => element.id === parseInt(ref.students)) || {name: ""}).name+"\t"+
            (allTeachers.find(element => element.id === parseInt(ref.teacher)) || {name: ""}).name + "\t"+
            timeStringToFloat(ref.hours)+"\t"+
            ref.lateCancelation+"\t"+
            ref.notes)
    };
    
    function changeText(event, name, auto=false, index=-1){
        if(index >= 0){
            let neVar = [...data]
            neVar[index][name] = event.target.value;
            setData(neVar)
        } else {
            if(auto){
                kokot[name] = event.id;
            } else {
                kokot[name] = event.target.value;
            }
        } 
    };

    function changeDate(event, name, index){
        let neVar = [...data]
        neVar[index][name] = new Date(event.setHours(20));
        setData(neVar)
    };

    const times = ["0:30", "0:45", "1:00", "1:15", "1:30", "1:45", "2:00", "2:15", "2:30", "2:45", "3:00"]    
    const timesOptions = [
        {name:"0:30", id: "0:30"}, 
        {name:"0:45", id: "0:45"}, 
        {name:"1:00", id: "1:00"}, 
        {name:"1:15", id: "1:15"}, 
        {name:"1:30", id: "1:30"}, 
        {name:"1:45", id: "1:45"}, 
        {name:"2:00", id: "2:00"}, 
        {name:"2:15", id: "2:15"}, 
        {name:"2:30", id: "2:30"}, 
        {name:"2:45", id: "2:45"}, 
        {name:"3:00", id: "3:00"}
    ]    
    const dateOptions = [
        {name:"Today", id: "today"},
        {name:"Yesterday", id: "yesterday"},
        {name:"This week", id: "this week"},
        {name:"Last week", id: "last week"},
        {name:"This month", id: "this month"},
        {name:"Last month", id: "last month"},
        {name:"This year", id: "this year"},
    ]
    const autos = 2;
    function containerFormatter(){
        var text = "150px 200px 16% 16% 16%";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+"6% 5%"
    }

    function changeAuto(input, index, name){
        let neVar = [...data]

        if(name === "rates"){
            name = "rate";
            var text = "";
            input = [input]
            input.map(elem => {return(elem.name)}).map(elem => {return text += elem +","});
            console.log(text)
            neVar[index][name] = text.slice(0, -1);
        } else {
            if(input !== null){
                neVar[index][name] = input.id;
            } else {
                neVar[index][name] = "";
            }
        }
        setData(neVar)
    }

    function changeAutoM(input, index){
        let newData = {...filters}
        if(input === null){
            newData[index] = ""
        } else {
            newData[index] = input.id;  
        }
        setFilters(newData);
    }

    function myFilter(){
        let newData = [...data];
        for (const [key] of Object.entries(filters)) {
            if(filters[key] !== "") {
                if(key !== "date"){
                    newData = newData.filter(function(record){
                        return String(record[key]) === String(filters[key]);
                    })
                } else {
                    if(filters[key] === "today"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isToday();
                        })
                    } else if(filters[key] === "yesterday"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isYesterday();
                        })
                    } else if(filters[key] === "this week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek()
                        })
                    } else if(filters[key] === "last week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek() -1;
                        })
                    } else if(filters[key] === "this month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month() 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "last month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month()-1 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "this year"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).year() === dayjs().year();
                        })
                    }
                }
            }   
        }
        return newData
    }
    
    function sortBy(key, des, cat=0){
        let newData = [...data];
        if(cat === 1){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return allTeachers.find(x => String(x.id) === String(a[key])).name > allTeachers.find(x => String(x.id) === String(b[key])).name ? des*1 : des*-1;
            }))   
        } else if(cat === 2){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return allStudents.find(x => String(x.id) === String(a[key])).name > allStudents.find(x => String(x.id) === String(b[key])).name ? des*1 : des*-1;
            }))  
        } else if(cat === 3){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            })) 
        } else if(cat === 4){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return new Date(b.date).getTime() > new Date(a.date).getTime() ? des*1 : des*-1;
            })) 
        }
    }
    const RateOptions = [
        {name:"Individual onsite"}, 
        {name:"Individual online"}, 
        {name:"Individual home"}
    ];
    let kokot ={}
    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    var exportDate = exported.date.split("T")[0].split("-");
    return (
            <>
                <div className={classes.topContainer}>
                    <MyInput type={"add"} addRecord={addRecord} text={"Add record"}/>
                    <div>
                        <MyInput type={"add"} addRecord={exportXLSX} text={"Export all data"}/>
                        <div>
                            Last export {
                                " " +
                                exportDate[2] + "/" + 
                                exportDate[1] + "/" + 
                                exportDate[0] + " - " +
                                exported.date.split("T")[1]
                            }   
                        </div>
                    </div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25}}>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"clear"}
                            function={clearFilters}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={dateOptions}
                            name={"date"}
                            placeholder={"Date"}
                            defaults={filters.date}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy} 
                            cat={4}
                            elem={"id"}
                            label={"name"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={allTeachers}
                            name={"teacher"}
                            placeholder={"Teachers"}
                            defaults={filters.teacher}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={1}
                            elem={"id"}
                            label={"name"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={allStudents}
                            name={"students"}
                            placeholder={"Students"}
                            defaults={filters.students}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={2}
                            elem={"id"}
                            label={"name"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={timesOptions}
                            name={"hours"}
                            placeholder={"Duration"}
                            defaults={filters.hours}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy} 
                            cat={3}
                            elem={"id"}
                            label={"name"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        Notes
                    </div>
                    <div className={classes.firstRow}>
                        Rate
                    </div>
                    <div className={classes.firstRow}>
                        Late cancellation
                    </div>
                    <div className={classes.firstRow}>
                        No charge
                    </div>
                    {
                    myFilter(data).map((entry, index) => {
                        if(typeof entry !== "undefined" ){   
                            if(!entry.edit){
                                kokot = entry;
                            }
                                return(
                                    <React.Fragment key={entry.id + "fragment"}>
                                        <div key={entry+index}>
                                            <MyInput 
                                                type={"copy"} 
                                                index={data.indexOf(entry)} 
                                                handleCopy={handleCopy} 
                                            />
                                            <MyInput 
                                                type={"edit"} 
                                                index={data.indexOf(entry)} 
                                                changeEdit={changeEdit} 
                                                edit={edit} 
                                                entry={entry} 
                                            />
                                            <MyInput 
                                                type={"delete"} 
                                                entry={entry} 
                                                index={data.indexOf(entry)} 
                                                myDelete={myDelete} 
                                            />
                                        </div>

                                        <MyInput 
                                            type={"date"} 
                                            entry={entry} 
                                            category={"date"}
                                            changeText={changeDate}
                                            index={data.indexOf(entry)}
                                        />

                                        <MyInput 
                                            type={"auto"} 
                                            entry={entry} 
                                            edit={edit} 
                                            placeholder={'Teacher'} 
                                            autoOptions={allTeachers}
                                            filterCondition={entry.students}
                                            filtered={allTeachers.filter(teacher => String(teacher.id) === String(allStudents.filter(x => String(x.id) === String(entry.students))[0]?.teachers))}
                                            index={data.indexOf(entry)}
                                            changeAuto={changeAuto}
                                            getDefault={entry.teacher}
                                            name={"teacher"}
                                        />

                                        <MyInput 
                                            type={"auto"} 
                                            entry={entry} 
                                            edit={edit} 
                                            placeholder={'Student'} 
                                            autoOptions={allStudents}
                                            filterCondition={entry.teacher}
                                            filtered={allStudents.filter(function(student) {
                                                return String(student.teachers) === String(entry.teacher) ||student.isNotStudent !== 0;
                                            })} 
                                            index={data.indexOf(entry)}
                                            changeAuto={changeAuto}
                                            getDefault={entry.students}
                                            name={"students"}
                                        />

                                        <MyInput 
                                            type={"select"} 
                                            entry={entry} 
                                            category={"hours"}
                                            changeText={changeText} 
                                            times={times}
                                            index={data.indexOf(entry)}
                                        />

                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Notes'} 
                                            default={entry.notes} 
                                            category={'notes'}
                                        />
                                        <MyInput 
                                            type={"ratesInput2"} 
                                            options={RateOptions}
                                            default={entry.rate === null? null: entry.rate}
                                            placeholder={"Rates"}
                                            name={"rate"}
                                            changeAuto={changeAuto}
                                            elem={"rate"}
                                            index={index}
                                            entry={entry}
                                        />
                                        <MyInput 
                                            type={"switch"} 
                                            entry={entry} 
                                            changeActive={changeActive} 
                                            index={data.indexOf(entry)} 
                                            active={entry.lateCancelation}
                                        />
                                        <MyInput 
                                            type={"switch"} 
                                            entry={entry} 
                                            changeActive={changeCharge} 
                                            index={data.indexOf(entry)} 
                                            active={entry.doNotCharge}
                                        />
                                    </React.Fragment>
                                )

                        } else {
                            return(<React.Fragment key={index + "aaasdaa"} />)
                        }
                    })
                    }
                <Dialog text={text} open={open} handleClose={handleClose} />
            </div>
        </>
    );
}
