export default {
    'data' : [
      {
          'name': 'Students',
          'url': '/overview',
          'index': 0,
      },
      {
        'name': 'Teachers',
        'url': '/personas',
        'index': 1,
      },
      {
        'name': 'Records',
        'url': '/data_settings',
        'index': 2,
      },
      {
        'name': 'Lesson rates',
        'url': '/rates',
        'index': 3,
      },
      {
        'name': 'Add credit',
        'url': '/balance',
        'index': 4,
      },
    ]
  }