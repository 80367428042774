import React, {useState} from 'react';
import useStyles from '../admin/adminStyle';
import {IconButton, Input, Switch, FormControlLabel, TextField, Button, MenuItem, Select } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Edit, Delete, Check, FileCopy, Add, ExpandLess, ExpandMore, Clear } from '@material-ui/icons';
import DatePicker from 'react-date-picker';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="£"
      />
    );
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


export default React.memo(
    function Card(props) {
        const classes = useStyles();
        const [des, setDes] = useState(1)

        function filter(array, key){
            if(array === []){
                return []
            }
            return array.filter(function(dataValue){
                if(typeof dataValue !== "undefined"){
                    return dataValue[key] !== "";
                }
                return ""
            })
        }

        if(props.type === "copy"){
            return (
                <IconButton aria-label="copy" onClick={(e) => props.handleCopy(props.index)}>
                    <FileCopy />
                </IconButton>
            )
        } else if(props.type === "edit"){
            return(
                <IconButton aria-label="edit" onClick={(e) => props.changeEdit(e, props.index, props.edit)}>
                    {props.entry.edit? <Edit /> : <Check />}
                </IconButton>
            )
        } else if(props.type === "auto"){
            return (
                <Autocomplete   
                    classes={{
                        root: classes.root2, 
                        inputRoot: classes.inputRoot,
                        input: classes.inputRoot
                    }}              
                    id="combo-box-demo"
                    options={props.filterCondition
                        ? props.filtered || null
                        : props.autoOptions}
                    getOptionSelected = {(option) => option.id}
                    getOptionLabel={(option) => {
                        if(option.lastName !== null){
                            return option.name + " "+ option.lastName
                        }
                            return option.name
                        }
                    }
                    value={
                        props.autoOptions.find(element => element.id === parseInt(props.getDefault)) || null
                    }
                    onChange={(e, input) => props.changeAuto(input, props.index, props.name)}
                    disabled={props.entry.edit} 
                    renderInput={(params) => <TextField {...params} placeholder={props.placeholder} style={{paddingTop: 16}} />}
                />
            )
        } else if(props.type === "input"){
            return(
                <Input  
                    defaultValue={props.default} 
                    disabled={props.entry.edit} 
                    inputProps={{ 'aria-label': 'description' }} 
                    onChange={(e) => props.changeText(e, props.category)}
                    classes={{root: classes.root, input: props.category==='accNumber'? classes.test : null}}
                    placeholder={props.placeholder}
                />
            )
        } else if(props.type === "switch"){
            return(
                <FormControlLabel
                    control={
                        <Switch   
                            disabled={props.entry.edit}
                            checked={props.active || false}
                            onChange={(e) => props.changeActive(e, props.index)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
            )
        } else if(props.type === "delete"){
            return(
                <IconButton aria-label="delete" style={{width: 48, height: 48, display: "inline-flex", alignItems: "first baseline"}} onClick={() => props.myDelete(props.entry.id, props.index)}>
                    <Delete />
                </IconButton>
            )
        } else if(props.type === "add"){
            return(
                <Button aria-label="edit" onClick={props.addRecord} style={{placeSelf: "flex-start"}} >
                    <Add /> {props.text}
                </Button>
            )
        } else if(props.type === "addBig"){
            return(
                <Button aria-label="edit" onClick={props.addRecord} style={{placeSelf: "flex-start"}} >
                    <Add /> {props.text}
                </Button>
            )
        } else if(props.type === "select"){
            return(
                <Select
                    style={{marginRight: 20}}
                    key={"Select_1"+props.entry.student+props.entry.index}
                    disabled={props.entry.edit} 
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.entry.hours || ''}
                    onChange={(e) => props.changeText(e, props.category, false, props.index)}
                >
                    {
                        props.times.map((entry, index) => {
                            return(<MenuItem key={"time_item"+index} value={entry}>{entry}</MenuItem>)
                        })
                    }
                </Select>
            )
        } else if(props.type === "autoM"){
            let defaults = null;
            if(props.defaults !== ""){
                defaults = props.options.find(element => String(element[props.elem]) === String(props.defaults));
            }
    
            return(
                <div style={{display: "flex", justifyContent: "space-between", paddingRight: 20, alignItems: "inherit"}}>
                    <Autocomplete
                        style={{width: "100%"}}
                        id="checkboxes-tags-demo"
                        options={filter(props.options, props.label)}
                        getOptionLabel={(option) => props.label==="wholeName"?option.name +" " + option.lastName:option[props.label]}
                        getOptionSelected={(option) => option[props.label] !== ""}
                        onChange={(e, input) => props.changeAutoM(input, props.name, props.elem)}
                        //onChange={(e, input) => handleUpdateInput(e, value, props.placeholder)}
                        value={defaults || null}
                        classes={{tag: classes.tag, inputRoot: classes.inputRoot}}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="" placeholder={props.placeholder} style={{textTransform: "capitalize"}}/>
                        )}
                    /> 
                    <IconButton aria-label="edit" style={{height: 40, width: 40}} onClick={() => {
                        props.sortBy(props.name, des, props.cat);setDes(des*-1)}} 
                    >
                        {des===1? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                </div>
            )
        } else if (props.type === "date"){
            return(
                <DatePicker
                    format={"dd/MM/y"}
                    className={classes.datetime}
                    disabled={props.entry.edit} 
                    onChange={(date) => props.changeText(date, props.category, props.index)}
                    value={new Date(props.entry.date)}
                />
            ) 
        } else if (props.type === "clear"){
            return(
                <IconButton aria-label="edit" style={{height: 40, width: 40}} onClick={props.function}>
                    <Clear />
                </IconButton>
            )
        } else if(props.type === "float"){
            return(
                <TextField
                    defaultValue={props.entry[props.category]}
                    onChange={(e) => props.changeText(e, props.category)}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    classes={{root: classes.root}}
                    disabled={props.entry.edit}
                />
        )
        } else if(props.type === "addBalance"){
            return(
                <TextField
                    variant="outlined"
                    defaultValue={props.default}
                    onChange={(e) => props.changeText(e, "money")}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    classes={{root: classes.root3}}
                />
        )
        } else if(props.type === "addAuto"){
            return (
                <Autocomplete   
                    classes={{
                        root: classes.root3, 
                        inputRoot: classes.inputRoot,
                        input: classes.inputRoot
                    }}              
                    id="combo-box-demo"
                    options={props.autoOptions}
                    getOptionLabel={(option) => option.name + " "+ option.lastName}
                    value={props.value}
                    onChange={(e, input) => props.changeAuto(input, "student")}
                    renderInput={(params) => <TextField {...params} placeholder={props.placeholder} style={{paddingTop: 16}} />}
                />
            )
        } else if(props.type === "addInput"){
            return(
                <Input  
                    value={props.default} 
                    inputProps={{ 'aria-label': 'description' }} 
                    onChange={(e) => props.changeText(e.target.value, "notes")}
                    classes={{root: classes.root3}}
                    placeholder={props.placeholder}
                />
            )
        } else if(props.type === "ratesInput"){
            var defaults = []
            props.default.map(elem => {
                return(defaults.push(props.options.find(opt => opt.name === elem)))
            })
            if(defaults[0] === undefined){
                defaults = []
            }
            return(
                <Autocomplete
                    multiple = {props.name==="rates"? true : false}
                    style={{width: 400}}
                    id="tags-standard"
                    options={props.options}
                    getOptionLabel={(option) => typeof(option) !== "undefined"? option.name : null}
                    value={defaults}
                    classes={{tag: classes.tag, inputRoot: classes.inputRoot}}
                    onChange={(e, input) => props.changeAuto(input, props.index, "rates")}
                    disabled={props.entry.edit}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label=""
                            placeholder={props.placeholder}
                        />
                    )}
                />
            )
        } else if(props.type === "ratesInput2"){
            var defau = props.options.find(opt => opt.name === props.default)
            if(defau === undefined){
                defaults = null
            }
            return(
                <Autocomplete
                    multiple = {props.name==="rates"? true : false}
                    style={{width: 200, paddingTop: 16}}
                    id="tags-standard"
                    options={props.options}
                    getOptionLabel={(option) => typeof(option) !== "undefined"? option.name : null}
                    value={defau}
                    classes={{tag: classes.tag, inputRoot: classes.inputRoot}}
                    onChange={(e, input) => props.changeAuto(input, props.index, "rates")}
                    disabled={props.entry.edit}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label=""
                            placeholder={props.placeholder}
                        />
                    )}
                />
            )
        }
    }
)