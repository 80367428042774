
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    row: {
        display: "flex",
        fontSize: "1.5rem",
        justifyContent: "space-between",
    },
    row1: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",   
        fontWeight: "bold",
        fontSize: "1.5rem",
    },
    date: {
    },
    student: {
        display: "inline-flex",
        alignItems: "center",
        height: 50,
    },
    firstRow: {
        paddingBottom: 10,  
        borderBottom: "solid 1px black",
        marginBottom: 15,
        display: "inherit",
        alignItems: "center",
    },

}));export default useStyles;