
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    row: {
        display: "flex",
    },
    rows: {

    },
    root: {
        textTransform: "uppercase",
        marginRight: 20,
        minWidth: 180,
    },
    firstRow: {
        borderBottom: "solid 1px black",
        marginBottom: 15,
        display: "inherit",
        alignItems: "center",
        minHeight: 56,
    },
    secondRow: {

    },
    thirdRow: {

    },
    root1: {
        width:  "auto",
    },
    root2: {
        paddingRight: 20,
        minWidth: 180,
    },
    root3: {
        width: 250,
        height: 50,
        marginBottom: 25
    },
    datetime: {
        width: 175,
    },
    topContainer: {
        display: "flex",
        width: "50%",
        justifyContent: "space-between",
    },
    test: {
        textTransform: "uppercase",
    }
    

}));export default useStyles;