import React, {useState, useEffect} from 'react';
import useStyles from './adminStyle';
import Dialog from '../../globalComponents/dialog';
import MyInput from '../components/input'; 
import Export from './functions';

export default function AdminTeachers(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [edit, setEdit] = useState(null);
    const [exported, setExported] = useState(null);
    const [editing, setEditing] = useState(null);
    const [caller, setCaller] = useState([]);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({name: "", lastName: ""})

    function addRecord(){
        sendSave({
                    name:"", 
                    lastName: "", 
                    email:"", 
                    phone:"", 
                    password: String(Math.random().toString(36).slice(-8)), 
                    active: true, 
                    edit: true
                }, true);
    };

    function changeActive (event, index){
        let newData = [...data];
        newData[index].active = !newData[index].active;
        setData(newData);
    };

    function changeEdit(event, index, edit){
        let newData = [...data];
        if(editing !== null){
            if(editing !==  index){
                newData[editing].edit = true;
                setEdit(newData[index]);
                setEditing(index);
                sendSave(edit, false);
            } else {
                setEditing(null)
            }
        } else {
            setEdit(newData[index]);
            setEditing(index)
        }
        console.log(edit)
        if(!newData[index].edit){
            newData[index] = edit;
            sendSave(edit, false);
        }
        
        newData[index].edit = !newData[index].edit;
        setData(newData)
    };

    function handleClose(state){
        if(state === "Yes"){
            sendDelete(caller);
        };
        setOpen(false);
    };
    
    function myDelete(Id, Index){
        setCaller([Id, Index]);
        setText("user named - " + data[Index].name);
        setOpen(true);
    };

    function handleCopy(index){
        const ref = data[index];
        navigator.clipboard.writeText(
            ref.name+"\t"+
            ref.email+"\t"+
            ref.phone+"\t"+
            ref.password+"\t"+
            ref.active
        )
    };

    function clearFilters(){
        setFilters({name: "", lastName: ""});
    }

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/teachers`)
            .then(res => res.json())
            .then((data) => {
                var result = data.data.map(function(el) {
                    var o = Object.assign({}, el);
                    o.edit = true;
                    if(o.active === 1){
                        o.active = true;
                    } else {
                        o.active = false;
                    }
                    return o;
                })
                setExported(data.export)
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function sendSave(Newdata, New){
        fetch(`https://londonbeheroku.herokuapp.com/teacher/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: Newdata
            })
        })
        .then(res => res.json())
        .then((id) => {
            if(New){
                let array = [...data];
                Newdata.id = id;
                array.unshift(Newdata);
                setData(array);
            }
        });  
    }

    function sendDelete(idd){
        let newData = [...data];
        fetch(`https://londonbeheroku.herokuapp.com/teacher/delete`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: idd[0]}
            })
        })
        .then(res => res.json())
        .then((data) => {
            delete newData[idd[1]]
            setData(newData)
        }); 
    }
    
    const changeText = (event, name, auto=false) =>{
        if(auto){
            kokot[name] = event.id;
        } else {
            kokot[name] = event.target.value;
        }
    };

    function exportXLSX(){
        
        fetch(`https://londonbeheroku.herokuapp.com/export/teachers`, {
            method: 'POST',
            body: JSON.stringify({
                body: {date: new Date()}
            })
        })
        setExported({date: new Date().toISOString().split(".")[0]})
        Export(data);
    }

    const autos = 5;
    function containerFormatter(){
        var text = "150px ";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+"2%"
    }    
    function myFilter(){
        let newData = [...data];
        for (const [key] of Object.entries(filters)) {
            if(filters[key] !== "") {
                newData = newData.filter(function(record){
                    return String(record[key]) === String(filters[key]);
                })
            }
                
        }
        return newData
    }

    function changeAutoM(input, index, elem="id"){
        let newData = {...filters}
        if(input === null){
            newData[index] = ""
        } else {
            newData[index] = input[elem];  
        }
        setFilters(newData);
    } 

    function sortBy(key, des, cat=0){
        let newData = [...data];
        if(cat === 1){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            }))   
        }
    }
    let kokot = {}
    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    var exportDate = exported.date.split("T")[0].split("-");
    return (
        <>
            <div className={classes.topContainer}>
                <MyInput type={"add"} addRecord={addRecord} text={"Add teacher"}/>
                <div>
                    <MyInput type={"add"} addRecord={exportXLSX} text={"Export all data"}/>
                    <div>
                        Last export {
                            " " +
                            exportDate[2] + "/" + 
                            exportDate[1] + "/" + 
                            exportDate[0] + " - " +
                            exported.date.split("T")[1]
                        }
                    </div>
                </div>
            </div>
            <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25}}>
                    <div className={classes.firstRow}> 
                        <MyInput 
                            type={"clear"}
                            function={clearFilters}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={data}
                            name={"name"}
                            placeholder={"Name"}
                            defaults={filters.name}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={1}
                            elem={"name"}
                            label={"name"}
                        /> 
                    </div>
                    <div  className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={data}
                            name={"lastName"}
                            placeholder={"Surname"}
                            defaults={filters.lastName}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={1}
                            elem={"lastName"}
                            label={"lastName"}
                        /> 
                    </div>
                    <div className={classes.firstRow}>
                        Email
                    </div>
                    <div className={classes.firstRow}>
                        Phone number
                    </div>
                    <div className={classes.firstRow}>
                        Password
                    </div>
                    <div className={classes.firstRow}>
                        Active
                    </div>
                {
                myFilter(data).map((entry, index) => {
                    if(typeof entry !== "undefined"){               
                        if(!entry.edit){
                            kokot = entry;
                        }  
                        return(
                                    <React.Fragment key={entry+index+"fragment"+Math.random()}>
                                        <div key={entry+index}>
                                            <MyInput 
                                                type={"copy"} 
                                                index={index} 
                                                handleCopy={handleCopy} 
                                            />
                                            <MyInput 
                                                type={"edit"} 
                                                index={index} 
                                                changeEdit={changeEdit} 
                                                edit={edit} 
                                                entry={entry}
                                            />
                                            <MyInput 
                                                type={"delete"} 
                                                entry={entry} 
                                                index={index} 
                                                myDelete={myDelete} 
                                            />
                                        </div>  
                                        
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Name'} 
                                            default={entry.name} 
                                            category={'name'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Surname'} 
                                            default={entry.lastName} 
                                            category={'lastName'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Email'} 
                                            default={entry.email}
                                            category={'email'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Phone number'} 
                                            default={entry.phone}
                                            category={'phone'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Password'} 
                                            default={entry.password}
                                            category={'password'}
                                        />
                                        <MyInput 
                                            type={"switch"} 
                                            entry={entry} 
                                            changeActive={changeActive} 
                                            index={index} 
                                            active={entry.active}
                                        />
                                    </React.Fragment>
                        )
                    } else {
                        return (<React.Fragment key={index + "aaasdaa"} />)
                    }
                })
                }
           <Dialog text={text} open={open} handleClose={handleClose} />
        </div>
    </>
    );
}
