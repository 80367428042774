
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text1: {
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    flexFlow: "column wrap",
  },
  input: {
    marginTop: 15,
    marginBottom: 35,
    width: "100%",
  },
  root: {
    background: "#3a294d",
    color: "white",
  },
  if: {
    marginLeft: 20,
  },
  late: {
    width: "100%",
  },
  input1: {
    width: "100%",
    marginBottom: 50,
  },
  root2: {
    marginTop: 15,
    marginBottom: 35,
    width: "100%",
  },
  chosen:{
    backgroundColor: "#3a294d",
    color: "white",
    marginRight: 20,
  },
  card:{
    marginRight: 20
  }
}));export default useStyles;