import React, {useState, useEffect} from 'react';
import useStyles from './teacherStyle';
import Auth from '../../../login/auth';
import { format } from "date-fns";
import MyInput from '../../components/input'; 
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import Dialog from '../../../globalComponents/dialog';

dayjs.extend(isToday);
dayjs.extend(AdvancedFormat);
dayjs.extend(isYesterday);
dayjs.extend(isoWeek);

export default function AdminDashboard(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [filters, setFilters] = useState({students: "", hours: "", date: ""})
    const [data, setData] = useState([]);
    const [allStudents, setAllStudents] = useState([]);

    const [caller, setCaller] = useState([]);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/myrecords`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: Auth.id} 
            })
        })
        .then(res => res.json())
        .then((data) => {
                setAllStudents(data.students);
                setData(data.lessons);
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function timeStringToFloat(time) {
        if(time === ""){
          return 0
        }
        var hoursMinutes = time.split(/[.:]/);
        var hours = parseInt(hoursMinutes[0], 10);
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        return (hours + minutes / 60).toFixed(2);
    }

    
    const autos = 0;
    function containerFormatter(){
        var text = "20%  25% 20% ";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+"10% 20% 5%"
    }

    function myFilter(){
        let newData = [...data];
        for (const [key] of Object.entries(filters)) {
            if(filters[key] !== "") {
                if(key !== "date"){
                    newData = newData.filter(function(record){
                        return String(record[key]) === String(filters[key]);
                    })
                } else {
                    if(filters[key] === "today"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isToday();
                        })
                    } else if(filters[key] === "yesterday"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isYesterday();
                        })
                    } else if(filters[key] === "this week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek()
                        })
                    } else if(filters[key] === "last week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek() -1;
                        })
                    } else if(filters[key] === "this month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month() 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "last month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month()-1 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "this year"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).year() === dayjs().year();
                        })
                    }
                }
            }
            
        }
        return newData
    }

    function changeAutoM(input, index, elem="id"){
        let newData = {...filters}
        if(input === null){
            newData[index] = ""
        } else {
            newData[index] = input[elem];  
        }
        setFilters(newData);
    } 

    function sortBy(key, des, cat=0){
        let newData = [...data];
        if(cat === 1){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            }))   
        } else if(cat=== 2){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return new Date(b.date).getTime() > new Date(a.date).getTime() ? des*1 : des*-1;
            })) 
        }
    }

    function myDelete(Id, Index){
        var options = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        setCaller([Id, Index]);
        console.log(Id)
        console.log(Index)
        setText(" lesson dated " + new Date(data[Index].date).toLocaleDateString("en-US", options));
        setOpen(true);
    };

    function handleClose(state){
        if(state === "Yes"){
            sendDelete(caller);
        };
        setOpen(false);
    };

    function sendDelete(idd){
        let newData = [...data];
        fetch(`https://londonbeheroku.herokuapp.com/record/delete`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: idd[0]}
            })
        })
        .then(res => res.json())
        .then((data) => {
            delete newData[idd[1]]
            setData(newData.filter(function(v) {
                return v !== undefined;
              }))
        });
        
    }

    const timesOptions = [
        {name:"0:30", id: "0:30"}, 
        {name:"0:45", id: "0:45"}, 
        {name:"1:00", id: "1:00"}, 
        {name:"1:15", id: "1:15"}, 
        {name:"1:30", id: "1:30"}, 
        {name:"1:45", id: "1:45"}, 
        {name:"2:00", id: "2:00"}, 
        {name:"2:15", id: "2:15"}, 
        {name:"2:30", id: "2:30"}, 
        {name:"2:45", id: "2:45"}, 
        {name:"3:00", id: "3:00"}
    ]    
    const dateOptions = [
        {name:"Today", id: "today"},
        {name:"Yesterday", id: "yesterday"},
        {name:"This week", id: "this week"},
        {name:"Last week", id: "last week"},
        {name:"This month", id: "this month"},
        {name:"Last month", id: "last month"},
        {name:"This year", id: "this year"},
    ]


    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    return (
        <div className={classes.text1}>
            <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25, fontSize: "0.87rem"}}>
                <div className={classes.firstRow}>
                    <MyInput 
                        type={"autoM"} 
                        options={dateOptions}
                        name={"date"}
                        placeholder={"Date"}
                        defaults={filters.date}
                        changeAutoM={changeAutoM}
                        sortBy={sortBy} 
                        cat={2}
                        elem={"id"}
                        label={"name"}
                    />
                </div>
                <div className={classes.firstRow}>
                    <MyInput 
                        type={"autoM"} 
                        options={allStudents}
                        name={"students"}
                        placeholder={"Name"}
                        defaults={filters.students}
                        changeAutoM={changeAutoM}
                        sortBy={sortBy}
                        cat={1}
                        elem={"id"}
                        label={"name"}
                    /> 
                </div>
                <div className={classes.firstRow}>
                    <MyInput 
                        type={"autoM"} 
                        options={timesOptions}
                        name={"hours"}
                        placeholder={"Duration"}
                        defaults={filters.hours}
                        changeAutoM={changeAutoM}
                        sortBy={sortBy} 
                        cat={2}
                        elem={"id"}
                        label={"name"}
                    />
                </div>
                <div className={classes.firstRow}>
                    Late cancellation
                </div>
                <div className={classes.firstRow}>
                    Notes
                </div>
                <div className={classes.firstRow}>
                    Delete
                </div>
                {
                    myFilter(data).map((entry, index) => {
                        if(typeof entry !== "undefined" && entry !== null){  
                            const name  = allStudents.filter(student => String(student.id) === String(entry.students))[0];
                            return(
                                <React.Fragment key={entry+index+"fragment"}>
                                    <div className={classes.student}> {format(new Date(entry.date.replace("T", " ")), "dd/MM/yy")}</div>
                                    <div className={classes.student}>{name? name.name + " " + name.lastName: ""}</div>
                                    <div className={classes.student}>{entry.hours}</div> 
                                    <div className={classes.student}>{entry.lateCancelation===0? "No" : "Yes"}</div> 
                                    <div className={classes.student}>{entry.notes.replace(/(.{10})/g, "$1\n")}</div> 
                                    <div className={classes.student}>
                                        {
                                            dayjs(entry.date).unix()+5400 >= dayjs().unix()
                                            ? <MyInput type={"delete"} myDelete={myDelete} index={index} entry={entry}/> 
                                            : ""
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        } else {
                            return (<React.Fragment key={index + "aaasdaa"} />)
                        }
                    })
                }
                <div style={{marginTop: 150, borderTop: "1px solid black"}}>Total hours</div>
                <div style={{marginTop: 150, borderTop: "1px solid black"}}>{myFilter(data).reduce((a, b) => +a + +timeStringToFloat(b.hours), 0)} hrs</div>
            </div>
            <Dialog text={text} open={open} handleClose={handleClose} />
        </div>
    );
}
