import React, {useState, useEffect} from 'react';
import useStyles from './adminStyle';
import Dialog from '../../globalComponents/dialog';
import MyInput from '../components/input'; 
import Export from './functions';

export default function AdminDashboard(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [edit, setEdit] = useState(null);
    const [exported, setExported] = useState(null);
    const [editing, setEditing] = useState(null);
    const [allTeachers, setAllTeachers] = useState([]);
    const [caller, setCaller] = useState([]);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({name: "", lastName: "", teachers: ""})
    const [show, setShow] = useState(false);
    
    const [longSaveAuto, setLongSaveAuto] = useState([{
        name:"", 
        lastName:"",
        email:"", 
        phone:"", 
        teachers: null, 
        notes:"", 
        accNumber: "",
        credit:0,
        rates: null,
        active: true, 
        edit: true,
    }])

    
    
    function addRecord(){
        sendSave({
                    name:"", 
                    lastName:"",
                    email:"", 
                    phone:"", 
                    teachers:"", 
                    notes:"", 
                    accNumber: "",
                    credit:0,
                    rates:"",
                    active: true, 
                    edit: true,
                }, true);
    };

    function addRecordLong(){
        var neVar = [...longSaveAuto];

        if(longSave.name !== ""){
            neVar[0]["name"] = longSave.name;
        }
        if(longSave.lastName !== ""){
            neVar[0]["lastName"] = longSave.lastName;
        }
        if(longSave.email !== ""){
            neVar[0]["email"] = longSave.email;
        }
        if(longSave.phone !== ""){
            neVar[0]["phone"] = longSave.phone;
        }
        if(longSave.notes !== ""){
            neVar[0]["notes"] = longSave.notes;
        }
        if(longSave.accNumber !== ""){
            neVar[0]["accNumber"] = longSave.accNumber;
        }
        if(longSave.active !== ""){
            neVar[0]["active"] = longSave.active;
        }

        sendSave(neVar[0], true, true);
        longSave = {
            name:"", 
            lastName:"",
            email:"", 
            phone:"", 
            teachers: null, 
            notes:"", 
            accNumber: "",
            credit:0,
            rates: null,
            active: true, 
            edit: true,
        }
        setLongSaveAuto([longSave]);
        setShow(false);
    };

    function addRecordBig(){
        setShow(!show);
    };

    function changeActive (event, index){
        let newData = [...data];
        newData[index].active = !newData[index].active;
        setData(newData);
    };
    function changeActiveLong (event, index){
        longSave.active = !longSave.active;
    };

    function changeEdit(event, index, edit){
        let newData = [...data];
        if(editing !== null){
            if(editing !==  index){
                newData[editing].edit = true;
                setEdit(newData[index])
                setEditing(index);
                sendSave(edit, false);
            } else {
                setEditing(null)
            }
        } else {
            setEdit(newData[index])
            setEditing(index)
        }
        
        if(!newData[index].edit){
            newData[index] = edit;
            sendSave(edit, false);
        }
        if(newData[index] !== null){
            newData[index].edit = !newData[index].edit;
        }
        setData(newData)
    };

    function handleClose(state){
        if(state === "Yes"){
            sendDelete(caller);
        };
        setOpen(false);
    };
    
    function myDelete(Id, Index){
        setCaller([Id, Index]);
        setText("student named - " + data[Index].name);
        setOpen(true);
    };

    function handleCopy(index){
        const ref = data[index];
        navigator.clipboard.writeText(
            ref.name+"\t"+
            ref.email+"\t"+
            ref.phone+"\t"+
            allTeachers.find(element => element.id === parseInt(ref.teachers)).name + "\t"+
            ref.active
        )
    };

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/students`)
            .then(res => res.json())
            .then((data) => {
                var result = data.data.map(function(el) {
                    var o = Object.assign({}, el);
                    o.edit = true;
                    if(o.active === 1){
                        o.active = true;
                    } else {
                        o.active = false;
                    }
                    return o;
                })
                setExported(data.export);
                setAllTeachers(data.teachers);
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function sendSave(Newdata, New, shouldEdit = true){
        console.log(Newdata)
        fetch(`https://londonbeheroku.herokuapp.com/student/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: Newdata
            })
        })
        .then(res => res.json())
        .then((id) => {
            if(New){
                let array = [...data];
                Newdata.id = id;
                array.unshift(Newdata);
                array.edit = shouldEdit;
                setData(array);
            }
        });  
    }

    function sendDelete(idd){
        let newData = [...data];
        fetch(`https://londonbeheroku.herokuapp.com/student/delete`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: idd[0]}
            })
        })
        .then(res => res.json())
        .then((data) => {
            delete newData[idd[1]]
            setData(newData)
        }); 
    }

    function exportXLSX(){
        fetch(`https://londonbeheroku.herokuapp.com/export/students`, {
            method: 'POST',
            body: JSON.stringify({
                body: {date: new Date()}
            })
        })
        setExported({date: new Date().toISOString().split(".")[0]})
        
        Export(data.map(function(obj) {
            var o = allTeachers.find(element => element.id === parseInt(obj.teachers));
            if(typeof o !== "undefined"){
                obj.teachers = o.name + " " + o.lastName; 
            }
            return obj;
        }))
    }
    
    const changeText = (event, name, auto=false) =>{ 
        let newVar = {...edit};
        console.log(newVar)
        if(auto){
            newVar[name] = event.id;
        } else {
            if(name ==="accNumber"){
                kokot[name] = event.target.value.toUpperCase();
            } else {
                kokot[name] = event.target.value;
            }
        }
    };

    const changeTextLong = (event, name, auto=false) =>{ 
        if(auto){
            longSave[name] = event.id;
        } else {
            if(name ==="accNumber"){
                longSave[name] = event.target.value.toUpperCase();
            } else {
                longSave[name] = event.target.value;
            }
        }
    };

    const autos = 10;
    function containerFormatter(){
        var text = "150px ";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+""
    }

    function changeAuto(input, index, name){
        let neVar = [...data]

        if(name === "rates"){
            var text = "";
            input.map(elem => {return(elem.name)}).map(elem => {return text += elem +","});
            neVar[index][name] = text.slice(0, -1);
        } else {
            if(input !== null){
                neVar[index][name] = input.id;
            } else {
                neVar[index][name] = "";
            }
        }
        setData(neVar)
    }
    
    function changeAutoLong(input, index, name){
        let neVar = [...longSaveAuto];

        if(name === "rates"){
            var text = "";
            input.map(elem => {return(elem.name)}).map(elem => {return text += elem +","});
            neVar[index][name] = text.slice(0, -1);
        } else {
            if(input !== null){
                neVar[index][name] = input.id;
            } else {
                neVar[index][name] = "";
            }
        }
        if(longSave.name !== ""){
            neVar[index]["name"] = longSave.name;
        }
        if(longSave.lastName !== ""){
            neVar[index]["lastName"] = longSave.lastName;
        }
        if(longSave.email !== ""){
            neVar[index]["email"] = longSave.email;
        }
        if(longSave.phone !== ""){
            neVar[index]["phone"] = longSave.phone;
        }
        if(longSave.notes !== ""){
            neVar[index]["notes"] = longSave.notes;
        }
        if(longSave.accNumber !== ""){
            neVar[index]["accNumber"] = longSave.accNumber;
        }
        if(longSave.active !== ""){
            neVar[index]["active"] = longSave.active;
        }
        neVar[index]["credit"] = 0;
        neVar[index]["edit"] = true;
        setLongSaveAuto(neVar)
    }

    var longSave = {
        name:"", 
        lastName:"",
        email:"", 
        phone:"", 
        teachers:"",
        rates:"",
        notes:"", 
        accNumber: "",
        credit:0,
        active: true, 
        edit: true,
}
    
    function myFilter(){
        let newData = [...data];
        for (const [key] of Object.entries(filters)) {
            if(filters[key] !== "") {
                newData = newData.filter(function(record){
                    return String(record[key]) === String(filters[key]);
                })
            }
        }
        return newData
    }

    function changeAutoM(input, index, elem="id"){
        let newData = {...filters}
        if(input === null){
            newData[index] = ""
        } else {
            newData[index] = input[elem];  
        }
        setFilters(newData);
    } 

    function sortBy(key, des, cat=0){
        let newData = [...data];
        if(cat === 1){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            }))   
        } else if(cat === 2){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return allTeachers.find(x => String(x.id) === String(a[key])).name > allTeachers.find(x => String(x.id) === String(b[key])).name ? des*1 : des*-1;
            })) 
        }
    }

    function clearFilters(){
        setFilters({name: "", lastName: "", teachers: ""});
    }
    const RateOptions = [
        {name:"Individual onsite"}, 
        {name:"Individual online"}, 
        {name:"Individual home"}
    ];
    let kokot = {};
    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    var exportDate = exported.date.split("T")[0].split("-");
    
    return (
        <>
            <div className={classes.topContainer}>
                <div style={{display: 'grid'}}>
                    <MyInput type={"add"} addRecord={addRecord} text={"Add student"}/>
                    <MyInput type={"addBig"} addRecord={addRecordBig} text={"Add student - vertical"}/>
                    {
                        show? 
                            <>
                                <div style={{marginLeft: 13}}>
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Name'} 
                                        default={""} 
                                        category={'name'}
                                    />
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Surname'} 
                                        default={""} 
                                        category={'lastName'}
                                    />
                                    {<br/>}
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Email'} 
                                        default={""}
                                        category={'email'}
                                    />
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Phone number'} 
                                        default={""}
                                        category={'phone'}
                                    />
                                    <MyInput 
                                        type={"auto"} 
                                        entry={{disabled: false}} 
                                        edit={longSaveAuto} 
                                        placeholder={'Teacher'} 
                                        autoOptions={allTeachers}
                                        filterCondition={false}
                                        index={0}
                                        changeAuto={changeAutoLong}
                                        getDefault={longSaveAuto[0].teachers}
                                        name={"teachers"}
                                    />
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Notes'} 
                                        default={""}
                                        category={'notes'}
                                    />
                                    <MyInput 
                                        type={"input"} 
                                        entry={{disabled: false}} 
                                        changeText={changeTextLong} 
                                        placeholder={'Acc. id'} 
                                        default={""}
                                        category={'accNumber'}
                                        toUpper={true}
                                    />
                                    
                                    <MyInput 
                                        type={"ratesInput"} 
                                        options={RateOptions}
                                        default={longSaveAuto[0].rates === null? [] : longSaveAuto[0].rates.split(",")}
                                        placeholder={"Rates"}
                                        name={"rates"}
                                        changeAuto={changeAutoLong}
                                        elem={"rates"}
                                        index={0}
                                        entry={{disabled: false}}
                                    />
                                    Active:&nbsp;&nbsp; 
                                    <MyInput 
                                        type={"switch"} 
                                        entry={{disabled: false}} 
                                        changeActive={changeActiveLong} 
                                        index={0} 
                                        active={true}
                                    />
                                    {<br/>}
                                </div>
                                <MyInput type={"addBig"} addRecord={addRecordLong} text={"Save student"}/>
                            </> : <> </>
                    }
                </div>
                
                <div style={{position: "absolute", right: "39vw"}}>
                    <MyInput type={"add"} addRecord={exportXLSX} text={"Export all data"}/>
                    <div>
                        Last export {
                            " " +
                            exportDate[2] + "/" + 
                            exportDate[1] + "/" + 
                            exportDate[0] + " - " +
                            exported.date.split("T")[1]
                        }
                    </div>
                </div>
            </div>
            
            <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25}}>
                    <div className={classes.firstRow}> 
                        <MyInput 
                            type={"clear"}
                            function={clearFilters}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={data}
                            name={"name"}
                            placeholder={"Name"}
                            defaults={filters.name}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={1}
                            elem={"name"}
                            label={"name"}
                        /> 
                    </div>
                    <div  className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={data}
                            name={"lastName"}
                            placeholder={"Surname"}
                            defaults={filters.lastName}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={1}
                            elem={"lastName"}
                            label={"lastName"}
                        /> 
                    </div>
                    <div className={classes.firstRow}>
                        Email
                    </div>
                    <div className={classes.firstRow}>
                        Phone number
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={allTeachers}
                            name={"teachers"}
                            placeholder={"Teacher"}
                            defaults={filters.teachers}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={2}
                            elem={"id"}
                            label={"name"}
                        /> 
                    </div>
                    <div className={classes.firstRow}>
                        Notes
                    </div>
                    <div className={classes.firstRow}>
                        Acc. id
                    </div>
                    <div className={classes.firstRow}>
                        Credit
                    </div>
                    <div className={classes.firstRow}>
                        Rates
                    </div>
                    <div className={classes.firstRow}>
                        Active
                    </div>
                {
                myFilter(data).map((entry, index) => {
                    if(typeof entry !== "undefined" && entry !== null){  
                        if(!entry.edit){
                            kokot = entry;
                        }              
                        return(
                                    <React.Fragment key={entry+index+"fragment"+Math.random()}>
                                        <div key={entry+index}>
                                            <MyInput 
                                                type={"copy"} 
                                                index={index} 
                                                handleCopy={handleCopy}
                                            />
                                            <MyInput 
                                                type={"edit"} 
                                                index={index} 
                                                changeEdit={changeEdit} 
                                                edit={edit} 
                                                entry={entry}
                                            />
                                            <MyInput 
                                                type={"delete"} 
                                                entry={entry} 
                                                index={index}
                                                myDelete={myDelete} 
                                            />
                                        </div>  
                                        
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Name'} 
                                            default={entry.name} 
                                            category={'name'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Surname'} 
                                            default={entry.lastName} 
                                            category={'lastName'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Email'} 
                                            default={entry.email}
                                            category={'email'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Phone number'} 
                                            default={entry.phone}
                                            category={'phone'}
                                        />
                                        <MyInput 
                                            type={"auto"} 
                                            entry={entry} 
                                            edit={edit} 
                                            placeholder={'Teacher'} 
                                            autoOptions={allTeachers}
                                            filterCondition={false}
                                            index={index}
                                            changeAuto={changeAuto}
                                            getDefault={entry.teachers}
                                            name={"teachers"}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Notes'} 
                                            default={entry.notes}
                                            category={'notes'}
                                        />
                                        <MyInput 
                                            type={"input"} 
                                            entry={entry} 
                                            changeText={changeText} 
                                            placeholder={'Acc. id'} 
                                            default={entry.accNumber}
                                            category={'accNumber'}
                                            toUpper={true}
                                        />
                                        <div style={{display: "flex", alignItems: "center", width: 75}}>
                                            {entry.credit}
                                        </div>
                                        <MyInput 
                                            type={"ratesInput"} 
                                            options={RateOptions}
                                            default={entry.rates === null? []: entry.rates.split(",")}
                                            placeholder={"Rates"}
                                            name={"rates"}
                                            changeAuto={changeAuto}
                                            elem={"rates"}
                                            index={index}
                                            entry={entry}
                                        />
                                        <MyInput 
                                            type={"switch"} 
                                            entry={entry} 
                                            changeActive={changeActive} 
                                            index={index} 
                                            active={entry.active}
                                        />
                                    </React.Fragment>
                        )
                    } else {
                        return (<React.Fragment key={index + "aaasdaa"} />)
                    }
                })
                }
           <Dialog text={text} open={open} handleClose={handleClose} />
        </div>
    </>
    );
}
