import React, { forwardRef, useState } from 'react';
import {List, ListItem, Button, Drawer } from '@material-ui/core';
import clsx from 'clsx';
import menuItems from './sideBarItems';
import { NavLink as RouterLink } from 'react-router-dom';
import useStyles from './menuBarStyle';
import { 
  Group, 
  School, 
  Today, 
  ExitToApp, 
  AccountBalanceWallet,
  GroupAdd } from '@material-ui/icons';
import Logo from "../../logo.png";

import Table from "../routes/admin/adminDashboard";
import TableTeachers from "../routes/admin/adminTeachers";
import TableRecords from "../routes/admin/adminRecords";
import Rate from '../routes/admin/adminRate';
import Balance from '../routes/admin/adminBalance';
import Auth from '../login/auth';

//https://londonbe heroku.herokuapp.com
//http://127.0. 0.1:8000

  const MenuBar = (props, test) => {
     
    const { className, staticContext, ...rest } = props;
    const [show, setCount] = useState(0);

    const classes  = useStyles();

    function getIcon(name, index){  
      const icons =[<Group className={clsx({
                                [classes.icon] : true})}/>,
                    <School className={clsx({
                                [classes.icon] : true})}/>,
                    <Today className={clsx({
                                [classes.icon] : true})}/>,
                    <AccountBalanceWallet className={clsx({
                                [classes.icon] : true})}/>,
                    <GroupAdd className={clsx({
                      [classes.icon] : true})}/>,
                    ]
      return icons[index];
    }

    const changeSection = (e, index) => {
      setCount(index);
    }
    const CustomRouterLink = forwardRef((props, ref) => (
      <div ref={ref} style={{ flexGrow: 1, paddingLeft: 25, }}>
        <RouterLink {...props} />
      </div>
    ));
    
    const handleMenu = ( children ) => {
      return children.map(({ children, name, url, links, index }) => {
        return (
          <List component='div' disablePadding key={ name }>
            <ListItem
              className={classes.item}
              disableGutters
              style={{padding:'0px'}}
              key={name}
            >
              <Button
                className={clsx({
                  [classes.btnRoot] : true,
                  [classes.button] : true,
                  [classes.selected] : props.test===name,
                })}
                onClick={(e)=> changeSection(e, index)}
              >
                {getIcon(name, index)}{name}
              </Button>
            </ListItem>
          </List>
        )})
    };
    function getComponent (){
      switch(show){
        case 0: 
          return  (
            <div>
              <div className={classes.upperName}>
                Students
              </div>
              <div className={classes.tableLines}>
                <Table />
              </div>
            </div>
          );
        case 1: 
          return  (
            <div>
              <div className={classes.upperName}>
                Teachers
              </div>
              <div className={classes.tableLines}>
                <TableTeachers />
              </div>
            </div>
          );
        case 2: 
          return  (
            <div>
              <div className={classes.upperName}>
                Records
              </div>
              <div className={classes.tableLines}>
                <TableRecords />
              </div>
            </div>
          );
          case 3: 
            return  (
              <div>
                <div className={classes.upperName}>
                  Rate
                </div>
                <div className={classes.tableLines}>
                  <Rate />
                </div>
              </div>
            );
          case 4: 
            return  (
              <div>
                <div className={classes.upperName}>
                  Balance
                </div>
                <div className={classes.tableLines}>
                  <Balance />
                </div>
              </div>
            );
        default: 
          return (<div><div className={classes.upperName}>
            Students
          </div>
          <div className={classes.tableLines}>
              <Table />
          </div></div>);  
      }
    }
    return (
    <div>
      
      <Drawer
          anchor='left'
          classes={{ paper: classes.drawer }}
          open={true}
          variant='persistent'
        >
          <List {...rest} className={clsx(classes.root, className)} >
            <div className={classes.labels}>
              <div className={classes.label1}>
                Admin Dashboard
              </div>
            </div>
              { handleMenu(menuItems.data) } 
          </List>
          <Button
            className={clsx({
              [classes.btnRoot] : true,
              [classes.button] : true,
            })}
            component={CustomRouterLink}
            to={`/`}
            onClick={() => {Auth.logout()}}
          >
            <ExitToApp /> Logout
          </Button>
          <img src={Logo} className={classes.logo} alt="fireSpot" />
      </Drawer>
      <div className={ classes.content }>
        <div className={ classes.mainContent }>
          {getComponent ()}
        </div>
      </div>
      
    </div>
   )
}
export default MenuBar;