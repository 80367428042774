import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Lock from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Logo from "../../logo.png";
import { makeStyles } from '@material-ui/core/styles';
import Auth from "./auth";
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.russiancentre.co.uk/">
        The Russian Language Centre
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "100%",
    marginTop: 50,
  },
}));


export default function SignIn(props) {

  let history = useHistory()
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  if(typeof Cookies.get('teacher_id') !== "undefined")
  {
    Auth.login(() => history.push("/teacher"), Cookies.get('teacher_id'), () => history.push("admin"))
    return (<div></div>)
  } else {
  
  if (Auth.isAuthenticated()) {

    history.push("/teacher")
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <img src={Logo} className={classes.logo} alt="fireSpot" />

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Lock />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
            </Typography>
          <form className={classes.form} 
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              Auth.login(() => history.push("/teacher"), value, () => history.push("admin"))
            }
            }>
            <TextField
              value={value}
              onChange={e => setValue(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="text"
              label="Input PIN number"
              name="text"
              autoComplete="text"
              autoFocus
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
              </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container >
    );
  }
}

}