import Cookies from 'js-cookie';
class Auth {
    constructor() {
        this.authenticated = false
        this.admin = false
        this.id = null
        this.name = null
    }

    login(cb, id, cb_1) {
        console.log(id)
        fetch(`https://londonbeheroku.herokuapp.com/login/${id}`)
            .then(res => res.json())
            .then((data) => {
                if (data.login) {
                    this.authenticated = true;
                    this.id = data.id;
                    if (data.admin) {
                        this.admin = true;
                        Cookies.set('teacher_id', id)
                        cb_1();
                    } else {
                        console.log(id)
                        this.name = data.name;
                        Cookies.set('teacher_id', id)
                        cb();
                    }

                } else {
                    alert("Wrong code");
                    Cookies.remove('teacher_id')
                }
            })
            .catch(console.log)

    }

    logout(id) {
        this.authenticated = false;
        Cookies.remove('teacher_id');
    }
    isAuthenticated() {
        return this.authenticated;
    }
    isAdmin() {
        return this.admin;
    }
}

export default new Auth();