import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Auth from './components/login/auth'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (Auth.isAuthenticated()) {
                    if (props.location.pathname === "/admin") {
                        if (Auth.isAdmin()) {
                            return <Component {...props} />
                        } else {
                            return <Redirect to={
                                {
                                    pathname: "/teacher",
                                    state: {
                                        from: props.location
                                    }
                                }
                            } />
                        }

                    }
                    return <Component {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
            }
        />
    )
}
export default ProtectedRoute;