import XLSX from 'xlsx';


export default function Export(data) {
    var myData = [[]];

    function timeToDecimal(t) {
        var arr = t.split(':');
        var dec = parseInt((arr[1]/6)*10, 10);
    
        return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
    }   

    if(typeof data[0] !== "undefined"){
        for (const [key] of Object.entries(data[0])) {
            if(
                key !== "edit" && 
                key !== "id" && 
                key !== "admin" &&
                key !== "password"
            ){
                myData[0].push(key)
            }
            
        }
        data.forEach(element => {
            var dat = [];
            myData[0].forEach(elementt => {
                if(elementt === "hours"){
                    dat.push(timeToDecimal(element[elementt]))
                } else {
                    dat.push(element[elementt])
                }
                
            })
            myData.push(dat);
        });
        var worksheet = XLSX.utils.aoa_to_sheet(myData);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        let exportFileName = `workbook_export.xls`;
        XLSX.writeFile(new_workbook, exportFileName)
    }
    
}