import React, {useState, useEffect} from 'react';
import useStyles from './dashboardStyles';
import { Button, Select, MenuItem } from '@material-ui/core';
import Auth from '../../../login/auth';
import Selector from './dashboardSelector';

export default function Dashboard(props) {
    const classes = useStyles();
    const [students, setStudents] = useState([]);
    const [student, setStudent] = useState('');
    const [hours, setHours] = useState('1:30');
    const [lateCancelation, setLateCancelation] = useState(false);
    const [notes, setNotes] = useState('');
    const [free, setFree] = useState(false);
    const [summary, setSummary] = useState(false);
    const [rate, setRate] = useState(0);
    const [myId, setMyId] = useState(Auth.id);
    const [type, setType] = useState(0);
    const [allTeachers, setAllteachers] = useState([]);

    useEffect(() => {
          fetchStudents(Auth.id)
    }, [props])
    
    function fetchStudents(id){
        fetch(`https://londonbeheroku.herokuapp.com/mystudents`, {
            method: 'POST',
            body: JSON.stringify({
                body: {id: id} 
            })
        })
        .then(res => res.json())
        .then((data) => {
            setAllteachers(data.teachers);
            setStudents(data.students);
            console.log(data.students)
        });

    }

    function sendAdd(){
        fetch(`https://londonbeheroku.herokuapp.com/record/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: {
                teacher: Auth.id, 
                students: student.id, 
                date: new Date(), 
                hours: hours, 
                lateCancelation: lateCancelation, 
                doNotCharge: free, 
                notes: notes,
                rate: rate
                } 
            })
        })
        .then(res => res.json())
        .then((data) => {
            if(data){
                alert("Successfully added");
                setStudent('');
                setHours("1:30");
                setLateCancelation(false);
                setNotes("");
                setFree(false);
                setSummary(false);
                setRate(0);
                setType(0);
          }

      })
    }

    function addRecord(){
        if(student === ""){
          alert("Please select student")
        } else if (hours === "0:00"){
          alert("Please select length of your lesson")
        } else {
            setSummary(true);
        }
    }

    function setValues(event, name){
        if(name === "rate"){
            setRate(event.target.value)
        } else if(name === "student"){
            setStudent(event);
        } else if(name === "hours"){
            setHours(event.target.value);
        } else if(name === "late"){
            setLateCancelation(!lateCancelation);
        } else if(name === "free"){
            setFree(!free);
        } else if(name === "notes"){
            setNotes(event.target.value)
        } else if(name === "myid"){
            console.log(event.target.value)
            setMyId(event.target.value)
        }
    }
    if(students.length === 0) return(<div>Loading</div>)
    if(summary && student !== null){
        var name = allTeachers.filter((person)=> {return String(person.id) ===  String(myId)})[0];
        console.log(name)
        return (
            <div>
                <div className={classes.text1}>
                    {type !== 2? "Lesson input": "Activity"} summary<br />
                    {myId !== Auth.id? "Cover for " + name.name+ " "+ name.lastName :""}<br />
                    Selected {type !== 2? "student": "activity"} : {student.name + " " + student.lastName}<br />
                    {type !== 2? "Rate was: {rate} <br />": ""}
                    {type !== 2? "Lesson": "Activity"} length: {hours}<br />
                    {type !== 2? 'Was it a late cancellation? {lateCancelation? " Yes": " No"}<br />' : ""}
                    {type !== 2? 'Was the lesson free of charge?{free? " Yes": " No"}<br />' : ""}
                    Your notes: {notes}<br />         
                </div>
                <div style={{marginTop: 25}}>
                    <Button aria-label="edit" classes={{root: classes.root}} onClick={sendAdd}>
                        Confirm {type !== 2? "lesson" : "activity"}
                    </Button>
                    <Button aria-label="edit" classes={{root: classes.root}} onClick={() => {setSummary(false)}}>
                        Go back 
                    </Button>
                </div>  
            </div>
        )
    }
    return (
        <div className={classes.text1}>
            <div style={{marginBottom: 30}}>
                <Button 
                    className={type===0? classes.chosen: classes.card}
                    onClick={() => {
                        setType(0);
                        setMyId(Auth.id);
                        setStudent('')
                    }}
                >
                    My Lesson
                </Button>
                <Button 
                    className={type===1? classes.chosen: classes.card}
                    onClick={() => {
                        setType(1);
                        setStudent('');
                    }}
                >
                    Cover lesson
                </Button>
                <Button 
                    className={type===1? classes.chosen: classes.card}
                    onClick={() => {
                        setType(2);
                        setStudent('');
                    }}
                >
                    Non-teaching work
                </Button>
                
            </div>
            {   type === 2? 
                    <Selector
                        students={students.filter((person)=> {return person.isNotStudent === 1})} 
                        student={student}
                        rates={rate}
                        rate={rate}
                        hours={hours}
                        free={free}
                        notes={notes}
                        setValues={setValues}
                        addRecord={addRecord}
                        type={1}
                    />
                :<>
                    {
                        type === 1?
                            <div> 
                                Select teacher
                                <Select
                                    key={"Select_teacher"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={""}
                                    onChange={(val) => setValues(val, "myid")}
                                    className={classes.input}
                                >
                                    {
                                        allTeachers.map((entry, index) => {
                                            return(
                                                <MenuItem 
                                                    key={"selector_item"+index} 
                                                    value={entry.id}
                                                >
                                                        {entry.name + " "+ entry.lastName}
                                                </MenuItem>
                                            )
                                        })  
                                    }
                                </Select>
                            </div>
                        : ""
                    }
                    <Selector
                        students={students.filter((person)=> {return String(person.teachers) ===  String(myId) || person.isNotStudent})} 
                        student={student}
                        rates={rate}
                        rate={rate}
                        hours={hours}
                        free={free}
                        notes={notes}
                        setValues={setValues}
                        addRecord={addRecord}
                        type={0}
                    />
                </>
            }
        </div>
    );
}
