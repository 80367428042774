import React from 'react';
import Login from './components/login/login'
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './protected_route';
import Admin from './components/menuBar/menuBar';
import Dashboard from './components/dashboard/dashboard/teacherBar'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const LoginHub = () => <Login />;
const Home = () => <Dashboard />;
const Admin_home = () => <Admin />;
//
//<Route path="/admin" component={Admin_home} />
function App() {
  return (
    <div style={{ height: "100%" }}>
      <BrowserRouter>

        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login" component={LoginHub} />
        <ProtectedRoute path="/teacher" component={Home} />
        <ProtectedRoute path="/admin" component={Admin_home} admin={true} />

      </BrowserRouter>
    </div>
  );
}

export default App;
