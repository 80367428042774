import React, {useState, useEffect} from 'react';
import useStyles from './adminStyle';
import MyInput from '../components/input'; 
import Export from './functions';

export default function AdminDashboard(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [edit, setEdit] = useState(null);
    const [exported, setExported] = useState(null);
    const [editing, setEditing] = useState(null);
    const [allTeachers, setAllTeachers] = useState([]);
    const [data, setData] = useState([]);

    function changeEdit(event, index, edit){
        let newData = [...data];
        if(editing !== null){
            if(editing !==  index){
                newData[editing].edit = true;
                setEdit(newData[index])
                setEditing(index);
                sendSave(edit, false);
            } else {
                setEditing(null)
            }
        } else {
            setEdit(newData[index])
            setEditing(index)
        }
        
        if(!newData[index].edit){
            newData[index] = edit;
            sendSave(edit, false);
        }
        newData[index].edit = !newData[index].edit;
        setData(newData)
    };

    function handleCopy(index){
        const ref = data[index];
        navigator.clipboard.writeText(
            ref.name+"\t"+
            ref.email+"\t"+
            ref.phone+"\t"+
            allTeachers.find(element => element.id === parseInt(ref.teachers)).name + "\t"+
            ref.active
        )
    };

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/rates`)
            .then(res => res.json())
            .then((data) => {
                var result = data.data.map(function(el) {
                    var o = Object.assign({}, el);
                    o.edit = true;
                    return o;
                })
                setExported(data.export)
                setAllTeachers(data.teachers);
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function sendSave(Newdata, New){
        fetch(`https://londonbeheroku.herokuapp.com/rates/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: Newdata
            })
        })
        .then(res => res.json())
        .then((id) => {
            if(New){
                let array = [...data];
                Newdata.id = id;
                array.unshift(Newdata);
                setData(array);
            }
        });  
    }

    function exportXLSX(){
        fetch(`https://londonbeheroku.herokuapp.com/export/rates`, {
            method: 'POST',
            body: JSON.stringify({
                body: {date: new Date()}
            })
        })
        setExported({date: new Date().toISOString().split(".")[0]})
        Export(data);
    }
    
    const changeText = (event, name, auto=false) =>{
        if(auto){
            kokot[name] = event.id;
        } else {
            kokot[name] = event.target.value.value;
        }
    };

    const autos = 7;
    function containerFormatter(){
        var text = "100px ";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+""
    }
    let kokot = {};
    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    var exportDate = exported.date.split("T")[0].split("-");
    return (
        <>
            <div className={classes.topContainer}>
                <div>

                </div>
                <div>
                    <MyInput type={"add"} addRecord={exportXLSX} text={"Export all data"}/>
                    <div>
                        Last export {
                            " " +
                            exportDate[2] + "/" + 
                            exportDate[1] + "/" + 
                            exportDate[0] + " - " +
                            exported.date.split("T")[1]
                        }
                    </div>
                </div>
            </div>
            
            <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25}}>
                    <div className={classes.firstRow}> 

                    </div>
                    <div className={classes.firstRow}>
                        Name and surname
                    </div>
                    <div  className={classes.firstRow}>
                        Individual onsite
                    </div>
                    <div className={classes.firstRow}>
                        Individual online
                    </div>
                    <div className={classes.firstRow}>
                        Individual home
                    </div>
                    <div className={classes.firstRow}>
                        Group evening
                    </div>
                    <div className={classes.firstRow}>
                        Group day
                    </div>
                    <div className={classes.firstRow}>
                        Group weekend
                    </div>
                {
                    data.map((entry, index) => {
                        if(
                            (typeof entry !== "undefined" && 
                            typeof allTeachers.find(
                                x => String(x.id) === String(entry.teacher) 
                            )?.name !== "undefined") || entry.teacher === 0){ 
                            if(!entry.edit){
                                kokot = entry;
                            }               
                            console.log(index+"fragment")
                            return(
                                        <React.Fragment key={index+"fragment"}>
                                            <div>
                                                <MyInput 
                                                    type={"copy"} 
                                                    index={index} 
                                                    handleCopy={handleCopy}
                                                />
                                                <MyInput 
                                                    type={"edit"} 
                                                    index={index} 
                                                    changeEdit={changeEdit} 
                                                    edit={edit} 
                                                    entry={entry}
                                                />
                                            </div>  
                                            <div style={{display: "inline-flex", alignItems: "center", minWidth: 180}}>
                                                {
                                                    entry.teacher === 0?
                                                    "Student rates":
                                                    allTeachers.find(x => String(x.id) === String(entry.teacher))?.name + " " +
                                                    allTeachers.find(x => String(x.id) === String(entry.teacher))?.lastName
                                                }
                                            </div>
                                            
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate1'} 
                                                default={entry.rate1}
                                                category={'rate1'}
                                                money={true}
                                            />
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate2'} 
                                                default={entry.rate2}
                                                category={'rate2'}
                                                money={true}
                                            />
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate3'} 
                                                default={entry.rate3}
                                                category={'rate3'}
                                                money={true}
                                            />
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate4'} 
                                                default={entry.rate4}
                                                category={'rate4'}
                                                money={true}
                                            />
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate5'} 
                                                default={entry.rate5}
                                                category={'rate5'}
                                                money={true}
                                            />
                                            <MyInput 
                                                type={"float"} 
                                                entry={entry} 
                                                changeText={changeText} 
                                                placeholder={'Rate6'} 
                                                default={entry.rate6}
                                                category={'rate6'}
                                                money={true}
                                            />
                                        </React.Fragment>
                            )
                        } else {
                            return ("")
                        }
                    })
                }
        </div>
    </>
    );
}
