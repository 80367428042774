import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'left',
    
    height: '100%',
  },
  drawer: {
    width: 250,
    border: '0',
    background: '#3a294d',
  },
  item: {
    height: 75,
    width: "100%",
  },
  button: {
    fontSize: "1.5rem",
    color: 'white',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: "100%",
    '&:hover': {
      color: "#45b2a6",
    },
    borderRadius: 0,
  },
  selected: {
    background: '#f77b04',
    color: "white",
  },
  btnRoot: {
    paddingLeft: 20,
    justifyContent: 'left !important',
    fontSize: '1.5rem',
  },
  content: {
    paddingLeft: 230,
    paddingTop: 50,
  },
  mainContent : {
    marginLeft: 50,
    marginRight: 50,
  },
  label1: {
    color: '#DFEFCA',
    display: 'inline-block',
    padding: '10px 10px 10px 0',
  },
  label2: {
    color: 'white',
    display: 'inline-block',
  },
  labels: {
    padding: 10,
    textAlign: 'center',
    fontSize: 35,
    fontWeight: 'bold',
    height: "auto",
    marginBottom: 40,
  },
  icon: {
    padding: 10,
    height: "2rem",
    width: "2rem",
  },
  iconSelected: {
    color: "white",
  },
  logo: {
    width: "94%",
    margin: 10,
  },
  upperName: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: 30,
  }
}));export default useStyles;