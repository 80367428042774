import React, { forwardRef, useState } from 'react';
import {List, ListItem, Button, Drawer } from '@material-ui/core';
import clsx from 'clsx';
import menuItems from './sideBarItems';
import { NavLink as RouterLink } from 'react-router-dom';
import useStyles from './teacherBarStyle';
import { Input, ExitToApp, FolderShared } from '@material-ui/icons';
import Logo from "../../../logo.png";
import Auth from '../../login/auth';
import Table from '../../routes/teacher/dasboard/newDashboard';
import View from '../../routes/teacher/allLessons/teacherAllView';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

  const MenuBar = (props, test) => {
     
    const { className, staticContext, ...rest } = props;
    const [show, setCount] = useState(0);

    const classes  = useStyles();

    function getIcon(name, index){  
      const icons =[<Input className={clsx({
                                [classes.icon] : true})}/>,
                    <FolderShared className={clsx({
                                [classes.icon] : true})}/>,
                    ]
      return icons[index];
    }

    const changeSection = (e, index) => {
      setCount(index);
      setState({ left: false });
    }
    const CustomRouterLink = forwardRef((props, ref) => (
      <div ref={ref} style={{ flexGrow: 1, paddingLeft: 25, }}>
        <RouterLink {...props} />
      </div>
    ));
    
    const handleMenu = ( children ) => {
      return children.map(({ children, name, url, links, index }) => {
        return (
          <List component='div' disablePadding key={ name }>
            <ListItem
              className={classes.item}
              disableGutters
              style={{padding:'0px'}}
              key={name}
            >
              <Button
                className={clsx({
                  [classes.btnRoot] : true,
                  [classes.button] : true,
                  [classes.selected] : props.test===name,
                })}
                onClick={(e)=> changeSection(e, index)}
              >
                {getIcon(name, index)}{name}
              </Button>
            </ListItem>
          </List>
        )})
    };
    function getComponent (){
      switch(show){
        case 0: 
          return  (
            <div>
              <div className={classes.upperName}>
                Input your lesson from today
              </div>
              <div className={classes.tableLines}>
                <Table />
              </div>
            </div>
          );
        case 1: 
          return  (
            <div>
              <div className={classes.upperName}>
                All your lessons
              </div>
              <div className={classes.tableLines}>
                <View />
              </div>
            </div>
          );
        default: 
          return (<div><div className={classes.upperName}>
            Input your lesson
          </div>
          <div className={classes.tableLines}>
              <Table />
          </div></div>);  
      }
    }




    const [state, setState] = React.useState({left: false});
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ left: open });
    };
    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List {...rest} className={clsx(classes.root, className)} >
              <div className={classes.labels}>
                <div className={classes.label1}>
                  Welcome back <br/>{Auth.name}
                </div>
              </div>
                { handleMenu(menuItems.data) } 
            </List>
            <Button
              className={clsx({
                [classes.btnRoot] : true,
                [classes.button] : true,
              })}
              component={CustomRouterLink}
              to={`/`}
              onClick={() => {Auth.logout()}}
            >
              <ExitToApp /> Logout
            </Button>

            <img src={Logo} className={classes.logo} alt="fireSpot" />
      </div>
    );





    return (
    <div>
      <BrowserView>
        <Drawer
            anchor='left'
            classes={{ paper: classes.drawer }}
            open={true}
            variant='persistent'
          >
            <List {...rest} className={clsx(classes.root, className)} >
              <div className={classes.labels}>
                <div className={classes.label1}>
                  Welcome back <br/>{Auth.name}
                </div>
              </div>
                { handleMenu(menuItems.data) } 
            </List>
            <Button
              className={clsx({
                [classes.btnRoot] : true,
                [classes.button] : true,
              })}
              component={CustomRouterLink}
              to={`/`}
              onClick={() => {Auth.logout()}}
            >
              <ExitToApp /> Logout
            </Button>
            <img src={Logo} className={classes.logo} alt="fireSpot" />
        </Drawer>
        <div className={ classes.content }>
          <div className={ classes.mainContent }>
            {getComponent ()}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <React.Fragment key={"left"} >
          <Button onClick={toggleDrawer("left", true)} style ={{marginTop: 15, backgroundColor: "rgb(58, 41, 77)", color: "white", borderRadius: "0 8px 8px 0 "}}>{"Show Menu"}</Button>
          <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)} classes={{ paper: classes.drawer }}>
            {list("left")}
          </Drawer>
        </React.Fragment>
          <div className={classes.mobileContent}>
            <div>
              {getComponent ()}
            </div>
          </div>
      </MobileView>
    </div>
   )
}
export default MenuBar;