import React from 'react';
import useStyles from './dashboardStyles';
import {MenuItem, Select, Button, FormControlLabel, Switch, Input, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function DashboardSelector(props) {
    const classes = useStyles();
    //const [isLoaded, setIsLoaded] = useState(false);
    const times = ["0:30", "0:45", "1:00", "1:15", "1:30", "1:45", "2:00", "2:15", "2:30", "2:45", "3:00"]
    const RateOptions = [
        "Individual onsite",
        "Individual online", 
        "Individual home"
    ];
    return (
        <div className={classes.text1}>
            {props.type === 0? "Select student:" : "Select activity" }
            <Autocomplete
                classes={{root: classes.root2}}              
                id="combo-box-demo"
                options={props.students}
                getOptionLabel={(option) => {
                    if(option.isNotStudent === 1){
                        return option.name
                    } else {
                        return option.name + " " + option.lastName
                    }
                }}
                value={props.student || null}
                onChange={(e, val) => props.setValues(val, "student")}
                renderInput={(params) => <TextField {...params} placeholder={props.type=== 0?"Student":"Activities"} style={{paddingTop: 16}} />}
            />
            {props.type === 0?
                <div>
                    Select rate
                    {
                        props.student !== null && props.student !== '' && props.student.rates !==  null && typeof props.student.rates !== "undefined"
                        ? props.student.rates.split(",").length > 0
                            ? <Select
                                key={"Select_10"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.rate|| "1"}
                                onChange={(val) => props.setValues(val, "rate")}
                                className={classes.input}
                            >
                                {
                                    props.student.rates.split(",").map((entryy, index) => {
                                        return(
                                            <MenuItem 
                                                key={"time_item"+entryy} 
                                                value={entryy}
                                            >
                                                    {entryy}
                                            </MenuItem>
                                        )
                                    })  
                                }
                            </Select>
                            : ""
                        : <Select
                            key={"Select_10"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.rate|| "1"}
                            onChange={(val) => props.setValues(val, "rate")}
                            className={classes.input}
                        >
                            {
                                RateOptions.map((entryy, index) => {
                                    return(
                                        <MenuItem 
                                            key={"time_item"+entryy} 
                                            value={entryy}
                                        >
                                                {entryy}
                                        </MenuItem>
                                    )
                                })  
                            }
                        </Select>
                    }
                </div>
                : <> </>
            }
            {props.type === 0? "Lesson length:" : "Activity length"}
            <Select
                key={"Select_1"}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.hours|| "1:30"}
                onChange={(val) => props.setValues(val, "hours")}
                className={classes.input}
                >
                {
                    times.map((entryy, index) => {
                        return(
                            <MenuItem 
                                key={"time_item"+index} 
                                value={entryy}
                            >
                                {entryy}
                            </MenuItem>
                        )
                    })  
                }
            </Select>
            {props.type === 0?
                <div className={classes.late}>
                    Was it a late cancellation?
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.lateCancelation}
                                onChange={(val) => props.setValues(val, "late")}
                                name="checkedB"
                                color="primary"
                                className={classes.if}
                            />
                        }
                    />
                </div>
                : <> </>
            }
            {props.type === 0?
                <div className={classes.late}>
                    Was the lesson free of charge?
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.free}
                                onChange={(val) => props.setValues(val, "free")}
                                name="checkedB"
                                color="primary"
                                className={classes.if}
                            />
                        }
                    />
                </div>
                : <> </>
            }
            <div className={classes.late}>
                Any notes?
            </div>
            <Input  
                value={props.notes} 
                inputProps={{ 'aria-label': 'description' }} 
                onChange={(val) => props.setValues(val, "notes")}
                classes={{root: classes.input1}}
            />
            <Button aria-label="edit" classes={{root: classes.root}} onClick={props.addRecord}>
                {props.type === 0? "Confirm lesson" : "Confirm activity"}
            </Button>
        </div>

        
    );
}
