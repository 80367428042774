export default {
    'data' : [
      {
          'name': 'New lesson',
          'url': '/overview',
          'index': 0,
      },
      {
        'name': 'All your lessons',
        'url': '/overview',
        'index': 1,
    }
    ]
  }