import React, {useState, useEffect} from 'react';
import useStyles from './adminStyle';
import MyInput from '../components/input'; 
import Export from './functions';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(isToday);
dayjs.extend(AdvancedFormat);
dayjs.extend(isYesterday);
dayjs.extend(isoWeek);

export default function AdminBalance(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [exported, setExported] = useState(null);
    const [allStudents, setAllStudents] = useState([]);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({name: "", lastName: "", teachers: ""})

    const [inputData, setInputData] = useState({student: null, money: "", notes: ""})
    const dateOptions = [
        {name:"Today", id: "today"},
        {name:"Yesterday", id: "yesterday"},
        {name:"This week", id: "this week"},
        {name:"Last week", id: "last week"},
        {name:"This month", id: "this month"},
        {name:"Last month", id: "last month"},
        {name:"This year", id: "this year"},
    ]

    useEffect(() => {
        fetch(`https://londonbeheroku.herokuapp.com/reports`)
            .then(res => res.json())
            .then((data) => {
                setExported(data.export);
                setAllStudents(data.students);
                setData(data.data);
                setIsLoaded(true);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            })
      }, [props])

    function sendSave(){
        var newPush = {
            change: inputData.money.target.value.floatValue,
            student: inputData.student.id,
            date: new Date(),
            id: 0,
            notes: inputData.notes,
            fullBalance: parseFloat(
                allStudents.filter((person) => {
                    return(person.id === inputData.student.id)
                })[0].credit) + parseFloat(inputData.money.target.value.floatValue),
            
        }
        var newVar = [...allStudents];
        newVar.filter((person) => {
            return(person.id === inputData.student.id)
        })[0].credit = newPush.fullBalance;
        setAllStudents(newVar);
        fetch(`https://londonbeheroku.herokuapp.com/reports/save`, {
            method: 'POST',
            body: JSON.stringify({
                body: newPush
            })
        })
        .then(res => res.json())
        .then((id) => {
            var newData = [...data];
            newPush.date = newPush.date.toISOString().split(".")[0]
            newData.unshift(newPush); 
            setData(newData);
        })
    }

    function exportXLSX(){
        fetch(`https://londonbeheroku.herokuapp.com/export/reports`, {
            method: 'POST',
            body: JSON.stringify({
                body: {date: new Date()}
            })
        })
        setExported({date: new Date().toISOString().split(".")[0]})
        
        Export(data.map(function(obj) {
            var o = allStudents.find(element => element.id === parseInt(obj.students));
            if(typeof o !== "undefined"){
                obj.teachers = o.name + " " + o.lastName; 
            }
            return obj;
        }))
    }

    const autos = 5;
    function containerFormatter(){
        var text = "50px ";
        for (var i = 0; i < autos; i++) {
            text += "auto ";
        }
        return text+""
    }

    function myFilter(){
        let newData = [...data];
        for (const [key] of Object.entries(filters)) {
            if(filters[key] !== "") {
                if(key !== "date"){
                    newData = newData.filter(function(record){
                        return String(record[key]) === String(filters[key]);
                    })
                } else {
                    if(filters[key] === "today"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isToday();
                        })
                    } else if(filters[key] === "yesterday"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isYesterday();
                        })
                    } else if(filters[key] === "this week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek()
                        })
                    } else if(filters[key] === "last week"){
                        newData = newData.filter(function(record){
                            return dayjs(record[key]).isoWeek() === dayjs().isoWeek() -1;
                        })
                    } else if(filters[key] === "this month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month() 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "last month"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).month() === dayjs().month()-1 
                                && dayjs(record[key]).year() === dayjs().year()
                        })
                    } else if(filters[key] === "this year"){
                        newData = newData.filter(function(record){
                            return  dayjs(record[key]).year() === dayjs().year();
                        })
                    }
                }
            }   
        }
        return newData
    }

    function changeAutoM(input, index, elem="id"){
        let newData = {...filters}
        if(input === null){
            newData[index] = ""
        } else {
            newData[index] = input[elem];  
        }
        setFilters(newData);
    } 

    function sortBy(key, des, cat=0){
        let newData = [...data];
        if(cat === 1){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            }))   
        } else if(cat === 2){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return allStudents.find(x => String(x.id) === String(a[key])).name > allStudents.find(x => String(x.id) === String(b[key])).name ? des*1 : des*-1;
            })) 
        } else if(cat === 3){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return a[key] > b[key] ? des*1 : des*-1;
            })) 
        } else if(cat === 4){
            setData(newData.sort((a, b) => {
                if(a[key]=== "" || a[key] === null) return 1;
                if(b[key] === "" || b[key] === null) return -1;
                return new Date(b.date).getTime() > new Date(a.date).getTime() ? des*1 : des*-1;
            })) 
        }
    }

    function changeData(val, cat){
        var newValue = {...inputData};
        newValue[cat] = val;
        setInputData(newValue);
    }

    function clearFilters(){
        setFilters({name: "", lastName: "", teachers: ""});
    }

    if(error) return <div>Error: {error.message}</div>;
    if(!isLoaded) return <div>Loading...</div>;
    var exportDate = exported.date.split("T")[0].split("-");
    return (
        <>
            <div className={classes.topContainer}>
                <div>
                </div>
                <div>
                    <MyInput type={"add"} addRecord={exportXLSX} text={"Export all data"}/>
                    <div>
                        Last export {
                            " " +
                            exportDate[2] + "/" + 
                            exportDate[1] + "/" + 
                            exportDate[0] + " - " +
                            exported.date.split("T")[1]
                        }
                    </div>
                </div>
            </div>
            <div style={{marginTop: 75, marginBottom: 75}}>
                Add balance
                <div style={{marginTop: 25, width: 250}}>
                    <MyInput 
                        type={"addBalance"} 
                        changeText={changeData} 
                        placeholder={'Amount of money'} 
                        default={inputData.money}
                        money={true}
                    />
                    <MyInput 
                        type={"addAuto"} 
                        placeholder={'Student name'} 
                        autoOptions={allStudents}
                        value={inputData.student}
                        changeAuto={changeData}
                    />
                    <MyInput 
                        type={"addInput"} 
                        default={inputData.notes}
                        changeText={changeData} 
                        placeholder={'Notes'} 
                    />
                    <MyInput type={"add"} addRecord={sendSave} text={"Add credit"}/>
                </div>
            </div>
            
            <div style={{display: "grid", gridTemplateColumns: containerFormatter(), paddingTop: 25}}>
                    <div className={classes.firstRow}> 
                        <MyInput 
                            type={"clear"}
                            function={clearFilters}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={dateOptions}
                            name={"date"}
                            placeholder={"Date"}
                            defaults={filters.date}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy} 
                            cat={4}
                            elem={"id"}
                            label={"name"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        <MyInput 
                            type={"autoM"} 
                            options={allStudents}
                            name={"student"}
                            placeholder={"Students"}
                            defaults={filters.student}
                            changeAutoM={changeAutoM}
                            sortBy={sortBy}
                            cat={2}
                            elem={"id"}
                            label={"wholeName"}
                        />
                    </div>
                    <div className={classes.firstRow}>
                        Change
                    </div>
                    <div className={classes.firstRow}>
                        Notes
                    </div>
                    <div className={classes.firstRow}>
                        Full balance
                    </div>
                {
                myFilter(data).map((entry, index) => {
                    var exportDate = entry.date.split("T")[0].split("-");
                    if(typeof entry !== "undefined" && entry !== null){         
                        return(
                                <React.Fragment key={entry+index+"fragment"+Math.random()}>
                                    <div>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {
                                            exportDate[2]+"/"+exportDate[1]+"/"+exportDate[0]
                                        }
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {
                                            allStudents.find(x => String(x.id) === String(entry.student)).name + " " +
                                            allStudents.find(x => String(x.id) === String(entry.student)).lastName
                                        }
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {entry.change} £
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {entry.notes}
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {entry.fullBalance} £
                                    </div>
                                </React.Fragment>
                        )
                    } else {
                        return (<React.Fragment key={index + "aaasdaa"} />)
                    }
                })
                }
        </div>
    </>
    );
}
